module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NGD3M8J"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Source+Sans+Pro:300,400,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kinderdagverblijf Madelief","short_name":"Madelief","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/madelief-icon.png"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"madelief","path":"/preview","previews":false,"omitPrismicScript":true,"pages":[{"type":"Single","match":"/:uid","path":"/single","component":"/opt/build/repo/src/templates/single.js"},{"type":"Article","match":"nieuws/:uid","path":"/article","component":"/opt/build/repo/src/templates/article.js"},{"type":"Archive","match":"/:uid","path":"/archives","component":"/opt/build/repo/src/templates/archive.js"},{"type":"Single","match":"/:uid","path":"/single","component":"/opt/build/repo/src/templates/single.js"},{"type":"Single","match":"groepen/:uid","path":"/single","component":"/opt/build/repo/src/templates/single.js"},{"type":"Single","match":"voor-ouders/:uid","path":"/single","component":"/opt/build/repo/src/templates/single.js"},{"type":"Single","match":"over-madelief/:uid","path":"/single","component":"/opt/build/repo/src/templates/single.js"},{"type":"Single","match":"inschrijven/:uid","path":"/single","component":"/opt/build/repo/src/templates/single.js"},{"type":"Single","match":"corona/:uid","path":"/single","component":"/opt/build/repo/src/templates/single.js"}],"sharpKeys":[{},"avatar"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
