import React, {useState, useEffect, useRef} from 'react';
import nl from 'date-fns/locale/nl';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('nl', nl)


const Datepicker = ({handleInputChange, name, hasMinDate, disabled, required }) => {
    const [currentDate, setcurrentDate] = useState();
    const dateRef = useRef(null);

    useEffect(() => {
        handleInputChange(dateRef.current.input);
    }, [currentDate])

    setDefaultLocale('nl');

    return (
        <SDatePicker 
            name={name}
            dateFormat="dd-MM-yyyy"
            selected={currentDate}
            onChange={date => setcurrentDate(date)}
            ref={dateRef}
            minDate={hasMinDate ? new Date() : false}
            disabled={disabled}
            required={required}
        />);
};

const SDatePicker = styled(DatePicker)`
    padding: 10px 12px;
    width: 100%;
    border: 1px solid var(--color-gray-lighter);
    border-radius: 3px;
    
    &:focus {
        border: 1px solid var(--color-gray-dark);
        outline: 0;
    }
`;

export default Datepicker;
