import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { fluidRange } from 'polished';
import Button from '../../base/button';
import Loader from '../../base/loader';
import Notice from '../../base/notice';
import Checkbox from '../../base/checkbox';
import Datepicker from '../datepicker';
import Daypicker from '../daypicker';
import Grid from '../../grid/grid';
import GridCell from '../../grid/gridCell';

//TODO: ACCOUNT VAN MADELIEF GEBRUIKEN

const form = ({ form }) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const formRef = useRef(null);
    
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setLoading(false);
            setSuccess(false);
            setError(true);
            
            return;
        }

        setLoading(true);

        const recaptchaToken = await executeRecaptcha('homepage');

        const newFormData = new FormData();

        newFormData.append('form-name', formRef.current.getAttribute('name'));
        newFormData.append('g-recaptcha-response', recaptchaToken);

        for (const key in formData) {
            newFormData.append(key, formData[key]);
        }

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(newFormData).toString()
        })
            .then(data => {
                console.log('Success:', data);
                
                if(data.status === 200) {
                    setLoading(false);
                    setSuccess(true);
                    setError(false);
                } else {
                    setLoading(false);
                    setSuccess(false);
                    setError(true);
                }

                const scrollTop = formRef.current.getBoundingClientRect().top + window.pageYOffset - 220;

                window.scrollTo({top: scrollTop, behavior: 'smooth'});
            })
            .catch(error => {
                alert(error);
                setLoading(false);
                setSuccess(false);
                setError(true);
            });
    };

    const handleDayChange = selection => {
        setFormData({ ...formData, ...selection});
    }

    const handleInputChange = target => {
        if (target.value === '') {
            const newFormData = { ...formData };

            delete newFormData[target.name];

            setFormData(newFormData);
        } else {
            setFormData({ ...formData, [target.name]: target.value });
        }
    };

    const handleCheckbox = event => {
        let checkboxes = formData[event.target.name];

        if (typeof checkboxes === 'undefined') checkboxes = [];

        const checkboxIndex = checkboxes.indexOf(event.target.value);

        if (checkboxIndex >= 0) {
            checkboxes.splice(checkboxIndex, 1);
        } else {
            checkboxes.push(event.target.value);
        }

        if (checkboxes.length === 0) {
            const newFormData = { ...formData };

            delete newFormData[event.target.name];
            delete newFormData.referrer_other;

            setFormData(newFormData);
        } else {
            const newFormData = { ...formData };

            if (event.target.value === 'other' && !event.target.checked) {
                delete newFormData.referrer_other;
                setFormData(newFormData);
            }

            setFormData({ ...newFormData, [event.target.name]: checkboxes });
        }
    };

    return (
        <SFormWrapper>
            <SHeading>Wil jij je kind inschrijven?</SHeading>
            
            <Notice type="error" visible={error}>Er is iets mis gegaan, probeer het nog een keer of neem contact met ons op.</Notice>
            <Notice type="success" visible={success}>We hebben de inschrijving ontvangen, we nemen zo snel mogelijk contact op.</Notice>
            
            <SForm ref={formRef} method="POST" name="subscribtionForm" onSubmit={handleSubmit} visible={!success} data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true">
                <SFormFields disabled={loading}>
                    <SFormInputItem width="49">
                        <SFormInputLabel>Voornaam kind</SFormInputLabel>
                        <SFormInput name="child_firstname" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem width="49">
                        <SFormInputLabel>Achternaam kind</SFormInputLabel>
                        <SFormInput name="child_lastname" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Naam ouder(s)</SFormInputLabel>
                        <SFormInput name="parents_name" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>(Verwachte) Geboortedatum</SFormInputLabel>
                        <Datepicker name="child_birthday" handleInputChange={handleInputChange} hasMinDate={false} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem width="39">
                        <SFormInputLabel>Postcode</SFormInputLabel>
                        <SFormInput name="zipcode" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem width="39">
                        <SFormInputLabel>Huisnummer</SFormInputLabel>
                        <SFormInput name="housenumber" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem width="19">
                        <SFormInputLabel>Toev.</SFormInputLabel>
                        <SFormInput name="addition" onChange={event => handleInputChange(event.target)} disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Straat</SFormInputLabel>
                        <SFormInput name="street" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Plaats</SFormInputLabel>
                        <SFormInput name="city" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Telefoonnummer</SFormInputLabel>
                        <SFormInput name="phone" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Emailadres</SFormInputLabel>
                        <SFormInput name="email" onChange={event => handleInputChange(event.target)} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Opvang nodig per</SFormInputLabel>
                        <Datepicker name="care_needed_per" handleInputChange={handleInputChange} hasMinDate={true} required disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Gewenste opvang op</SFormInputLabel>
                        <Daypicker handleDayChange={handleDayChange} disabled={loading} />
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Hoe bent u bij Madelief terechtgekomen?</SFormInputLabel>
                        <Grid>
                            <GridCell cellSizes={{ small: '100%', medium: '50%' }}>
                                <Checkbox
                                    type="checkbox"
                                    id="website"
                                    name="referrer_type"
                                    value="website"
                                    onChange={handleCheckbox}
                                    disabled={loading}
                                    label="Via de website"
                                />
                            </GridCell>
                            <GridCell cellSizes={{ small: '100%', medium: '50%' }}>
                                <Checkbox
                                    type="checkbox"
                                    id="acquaintances"
                                    name="referrer_type"
                                    value="acquaintances"
                                    onChange={handleCheckbox}
                                    disabled={loading}
                                    label="Via kennissen"
                                />
                            </GridCell>
                            <GridCell cellSizes={{ small: '100%', medium: '50%' }}>
                                <Checkbox
                                    type="checkbox"
                                    id="social-media"
                                    name="referrer_type"
                                    value="social-media"
                                    onChange={handleCheckbox}
                                    disabled={loading}
                                    label="Via social media"
                                />
                            </GridCell>
                            <GridCell cellSizes={{ small: '100%', medium: '50%' }}>
                                <Checkbox 
                                    type="checkbox" 
                                    id="other" 
                                    name="referrer_type" 
                                    value="other" 
                                    onChange={handleCheckbox} 
                                    disabled={loading} 
                                    label="Anders"
                                />
                                {typeof formData['referrer_type'] !== 'undefined' && formData['referrer_type'].includes('other') && (
                                    <>
                                        <SFormInput name="referrer_other_text" onChange={event => handleInputChange(event.target)} disabled={loading} placeholder="Namelijk…" />
                                    </>
                                )}
                                <SFormInput name="referrer_other_text" type="hidden"/>
                            </GridCell>
                        </Grid>
                    </SFormInputItem>

                    <SFormInputItem>
                        <SFormInputLabel>Akkoord met onze algemene voorwaarden</SFormInputLabel>

                        <Checkbox
                            type="checkbox"
                            id="terms"
                            name="terms"
                            value="ja"
                            onChange={handleCheckbox}
                            disabled={loading}
                            label="Op dit formulier zijn onze <a href='#' target='_blank' title='Bekijk de algemene voorwaarden'>algemene voorwaarden</a> van kracht, door het versturen van dit formulier gaat u akkoord met onze voorwaarden"
                            required={true}
                        />
                    </SFormInputItem>
                </SFormFields>

                <p className="hidden">
                    <label>Don’t fill this out if you’re human: 
                        <SFormInput name="bot-field" onChange={event => handleInputChange(event.target)} disabled={loading} />
                    </label>
                </p>

                <input type="hidden" name="form-name" value="subscribtionForm" />
                <input type="hidden" name="g-recaptcha-response" />

                <SFormFooter>
                    <Button button={true} type="submit" disabled={loading}>
                        {loading ? 'Verzenden…' : 'Inschrijven' }
                    </Button>

                    <Loader visible={loading} />
                </SFormFooter>
            </SForm>
        </SFormWrapper>
    );
};

const SFormWrapper = styled.div`
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    max-width: 720px;
`;

const SHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '18px',
            toSize: '23px'
        },
        '320px',
        '1440px'
    )}
    margin-bottom: 12px;
    font-weight: 700;
`;

const SForm = styled.form`
    position: relative;
    display: ${props => (props.visible ? 'block' : 'none')};
`;

const SFormFields = styled.fieldset`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 0;
    opacity: ${props => (props.disabled ? '.5' : '1')};
    transition: opacity .24s ease-in;
`;

const SFormInputItem = styled.div`
    width: ${({ width }) => (width ? `${width}%` : '100%')};

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
`;

const SFormInputLabel = styled.label`
    display: block;
    margin-bottom: 4px;
    font-weight: 700;
`;

const SFormInput = styled.input`
    padding: 10px 12px;
    width: 100%;
    border: 1px solid var(--color-gray-lighter);
    border-radius: 3px;
    transition: .24s ease-in;
    transition-property: background-color, color, border-color;
    font-family: var(--primary-font);

    &:focus {
        border: 1px solid var(--color-gray-dark);
        outline: 0;
    }
`;

const SFormFooter = styled.fieldset`
    display: flex;
    align-items: center;
    margin-top: 20px;
    border: 0;
`;

export default form;

form.propTypes = {
    // form: PropTypes.object
};
