import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { margin, fluidRange } from 'polished';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contain from '../components/base/contain';
import Grid from '../components/grid/grid';
import GridCell from '../components/grid/gridCell';
import PanoramaDefault from '../components/parts/panoramas/panoramaDefault';
import { TitleText, Quote, ExternalButton, InternalButton, Figure, Video, Carousel } from '../components/slices';
import CardDefault from '../components/parts/cards/cardDefault';
import breakpoints from '../styles/settings/breakpoints';

const Article = ({ data }) => {
    const prismicData = data.prismic.allArticles.edges[0]?.node;
    if (!prismicData) {
        return false;
    }

    const panoramaImage = prismicData.panorama_usecolor
        ? null
        : { src: prismicData.panorama_imageSharp.childImageSharp.fluid, alt: prismicData.panorama_image.alt };

    const panorama = {
        title: prismicData.panorama_title[0].text,
        text: prismicData.panorama_text[0].text,
        useColor: prismicData.panorama_usecolor,
        image: panoramaImage,
    };

    const getPublicationDate = () => {
        const publicationDate = prismicData.publication_date;
        const months = [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'october',
            'novemeber',
            'december',
        ];
        let newPublicationDate = new Date(publicationDate);
        let formattedDate = `${newPublicationDate.getDate()} ${
            months[newPublicationDate.getMonth()]
        } ${newPublicationDate.getFullYear()}`;

        if (publicationDate) {
            return <SDate datetime={publicationDate}>Gepubliceerd op: {formattedDate}</SDate>;
        }
    };

    const flexLayouts = () => {
        if (prismicData.layouts) {
            return prismicData.layouts.map((slice, key) => {
                const newKey = `${slice.type}-${key}`;

                switch (slice.type) {
                    // Text Slice
                    case 'title_text':
                        return <TitleText slice={slice.primary} key={newKey} />;
                    case 'quote':
                        return <Quote slice={slice.primary} key={newKey} />;
                    case 'external_button':
                        return <ExternalButton slice={slice.primary} key={newKey} />;
                    case 'internal_button':
                        return <InternalButton slice={slice.primary} key={newKey} />;
                    case 'figure':
                        return <Figure slice={slice.primary} key={newKey} />;
                    case 'video':
                        return <Video slice={slice.primary} key={newKey} />;
                    case 'carousel':
                        return <Carousel slice={slice.fields} key={newKey} />;
                    default:
                        return;
                }
            });
        }
    };

    const relatedItems = [];
    const { related, related_title } = prismicData;

    const relatedCards = () => {
        if (related) {
            const relatedTitle = related_title ? related_title[0].text : 'Gerelateerde nieuwsberichten';

            for (const [index, { card }] of related.entries()) {
                if (!card) {
                    return false;
                }

                const metaData = card._meta;
                const linkUid = metaData ? metaData.uid : '';
                const link = `nieuws/${linkUid}`;

                relatedItems.push(
                    <GridCell cellSizes={{ small: '100%', medium: '50%', large: `${(1 / 3) * 100}%` }} key={index}>
                        <CardDefault
                            content={{
                                image: card.archive_imageSharp.childImageSharp.fluid,
                                publicationDate: card.publication_date,
                                title: card.title[0].text,
                                description: card.description[0].text,
                                link: link,
                                label: card.label,
                            }}
                            key={index}
                        >
                            {card.title}
                        </CardDefault>
                    </GridCell>
                );
            }

            return (
                <SRelated>
                    <Contain>
                        <SRelatedHeading>{relatedTitle}</SRelatedHeading>
                        <Grid gutter="normal">{relatedItems}</Grid>
                    </Contain>
                </SRelated>
            );
        }
        return false;
    };

    return (
        <Layout>
            <SEO title={panorama.title} />
            <PanoramaDefault
                background={panorama.useColor}
                alignment="center"
                content={{
                    image: panorama.image,
                    title: panorama.title,
                    text: panorama.text,
                }}
            />
            <Contain>
                <SCopy>
                    {getPublicationDate()}
                    {flexLayouts()}
                </SCopy>
            </Contain>

            {relatedCards()}
        </Layout>
    );
};

const SCopy = styled.div`
    padding-top: 40px;
    padding-bottom: 60px;

    > * {
        ${margin(null, 'auto', null, 'auto')}
        max-width: 640px;
    }

    ${breakpoints.large} {
        padding-top: 60px;
        padding-bottom: 80px;
    }
`;

const SDate = styled.time`
    display: block;
    font-style: italic;
    color: var(--color-gray-darker);
`;

const SRelated = styled.div`
    padding-top: 60px;
    padding-bottom: 100px;
    border-top: 1px solid var(--color-gray-lighter);
`;

const SRelatedHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '20px',
            toSize: '25px',
        },
        '320px',
        '1440px'
    )}
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
`;

export const pageQuery = graphql`
    query ArticleQuery($uid: String) {
        prismic {
            allArticles(uid: $uid) {
                edges {
                    node {
                        publication_date
                        _meta {
                            uid
                        }
                        panorama_image
                        panorama_imageSharp {
                            childImageSharp {
                                fluid(maxWidth: 2560, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        panorama_title
                        panorama_text
                        panorama_usecolor
                        layouts {
                            ... on PRISMIC_ArticleLayoutsTitle_text {
                                type
                                primary {
                                    title_textTitle
                                    title_textText
                                }
                            }
                            ... on PRISMIC_ArticleLayoutsQuote {
                                type
                                primary {
                                    quote_text
                                    quote_cite
                                }
                            }
                            ... on PRISMIC_ArticleLayoutsExternal_button {
                                type
                                primary {
                                    button_label
                                    button_url {
                                        ... on PRISMIC__ExternalLink {
                                            url
                                        }
                                        ... on PRISMIC__FileLink {
                                            url
                                        }
                                        ... on PRISMIC__ImageLink {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_ArticleLayoutsInternal_button {
                                type
                                primary {
                                    button_label
                                    button_url {
                                        ... on PRISMIC_Home {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                        ... on PRISMIC_Article {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                        ... on PRISMIC_Team {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                        ... on PRISMIC_Archive {
                                            _meta {
                                                uid
                                                tags
                                            }
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_ArticleLayoutsFigure {
                                type
                                primary {
                                    caption
                                    image
                                    imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 1280, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_ArticleLayoutsVideo {
                                type
                                primary {
                                    url
                                }
                            }
                            ... on PRISMIC_ArticleLayoutsCarousel {
                                type
                                fields {
                                    image
                                }
                            }
                        }
                        related_title
                        related {
                            card {
                                ... on PRISMIC_Article {
                                    publication_date
                                    title
                                    description
                                    label
                                    archive_image
                                    _meta {
                                        uid
                                    }
                                    archive_imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 700, maxHeight: 500, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Article;

Article.propTypes = {
    data: PropTypes.object,
};
