import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTransition, useSpring, animated } from 'react-spring';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { position, size, fluidRange } from 'polished';
import Img from 'gatsby-image';
import scrollTo from 'gatsby-plugin-smoothscroll';
import breakpoints from '../../../styles/settings/breakpoints';
import Contain from '../../base/contain';
import Button from '../../base/button';
import DownIcon from '../../../images/icons/down.svg';

const PanoramaDefault = ({ items }) => {
    const slides = items;

    slides.map((item, index) => {
        item.id = index;
    });

    const [index, setIndex] = useState(0);
    const [reset, setReset] = useState(false);

    const progressSpring = useSpring({
        from: { width: '0%' },
        to: { width: '100%' },
        config: { duration: 7900 },
        reset: reset
    });

    const fadingSlidePropsTransition = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 700 }
    });

    const fadingTextPropsTransition = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 },
        enter: [{ whatever: 1 }, { whatsever: 1 }, { opacity: 1 }],
        leave: { opacity: 0 },
        config: { duration: 500 }
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(state => (state + 1) % items.length);
            setReset(true);
            setReset(false);
        }, 8000);
        return () => clearInterval(interval);
    }, []);

    const parseButton = (buttonLabel, buttonLink) => {
        if (!buttonLabel || !buttonLink) return false;
        const tag = buttonLink._meta.tags[0]; //TAG MUST BE THE SAME AS ARCHIVE TAG
        const uid = buttonLink._meta.uid;
        const link = tag === uid || typeof tag === 'undefined' ? `/${uid}` : `/${tag}/${uid}`;

        return (
            <SButton>
                <Button to={link}>{buttonLabel}</Button>
            </SButton>
        );
    };
    return (
        <SPanoramaContainer>
            {fadingSlidePropsTransition.map(({ item, props, key }) => (
                <animated.div key={key} style={{ ...props }}>
                    <SPanorama>
                        <SPanoramaImage>
                            <Img alt={item.carousel_image.alt} fluid={item.carousel_imageSharp.childImageSharp.fluid} />
                        </SPanoramaImage>

                        <SPanoramaContent>
                            {fadingTextPropsTransition.map(({ item, props, key }) => (
                                <animated.div
                                    key={key}
                                    style={{
                                        ...props,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '100%',
                                        transform: 'translate( -50%, -50%)'
                                    }}
                                >
                                    <Contain>
                                        <SPanoramaHeading>{RichText.render(item.carousel_title)}</SPanoramaHeading>
                                        <SPanoramaText>{RichText.render(item.carousel_text)}</SPanoramaText>
                                        {parseButton(item.button_label, item.button_link)}
                                    </Contain>
                                </animated.div>
                            ))}
                        </SPanoramaContent>
                        <SPanoramaProgressBar>
                            <animated.div style={progressSpring} />
                        </SPanoramaProgressBar>
                    </SPanorama>
                </animated.div>
            ))}
            <SPanoramaDown
                onClick={() => {
                    scrollTo('#intro');
                }}
            >
                <DownIcon />
            </SPanoramaDown>
        </SPanoramaContainer>
    );
};

const SPanoramaContainer = styled.div`
    position: relative;
    height: 80vh;
    max-height: 600px;

    ${breakpoints.large} {
        max-height: 1000px;
        height: 100vh;
    }
`;

const SPanorama = styled.div`
    ${position('absolute', 0, null, null, 0)}
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
`;

const SPanoramaImage = styled.figure`
    &,
    .gatsby-image-wrapper > div {
        ${position('absolute', '0', null, null, '0')}
        ${size('100%', '100%')}
    }

    .gatsby-image-wrapper {
        height: 100%;
    }

    &::after {
        ${position('absolute', '0', null, null, '0')}
        ${size('100%', '100%')}
        background-color: var(--color-black);
        opacity: 0.4;
        content: '';
    }
`;

const SPanoramaContent = styled.div`
    position: relative;
    width: 100%;
    color: var(--color-white);
`;

const SPanoramaHeading = styled.div`
    max-width: 700px;
    h1,
    h2 {
        ${fluidRange(
            {
                prop: 'font-size',
                fromSize: '40px',
                toSize: '60px'
            },
            '320px',
            '1000px'
        )}

        font-weight: 300;
        line-height: 1;
        letter-spacing: 1px;

        strong {
            font-weight: 700;
        }
    }
`;

const SPanoramaText = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '20px',
            toSize: '26px'
        },
        '320px',
        '1000px'
    )}
    max-width: 540px;
    margin-top: 30px;
    font-weight: 300;
    line-height: 1.3;
`;

const SPanoramaProgressBar = styled.span`
    ${position('absolute', null, null, 0, 0)};
    ${size('4px', '100%')};

    div {
        ${size('4px', '0')};

        background-color: var(--color-primary);
    }
`;

const SPanoramaDown = styled.button`
    display: none;

    ${breakpoints.large} {
        ${position('absolute', null, '30px', '30px', null)};
        ${size('40px', '40px')}
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--color-white);
        border-radius: 50%;
        background-color: transparent;
        opacity: 0.8;
        animation: down 1s 1s 7 ease-in;

        @keyframes down {
            0% {
                transform: translateY(0);
            }

            50% {
                transform: translateY(30%);
            }

            100% {
                transform: translateY(0);
            }
        }
    }
`;

const SButton = styled.div`
    margin-top: 20px;
`;

export default PanoramaDefault;

PanoramaDefault.propTypes = {
    background: PropTypes.bool,
    alignment: PropTypes.string,
    items: PropTypes.array.isRequired
};
